import React from "react";

export const Download = (props) => {
  return (
    <div id="download" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Download</h2>
          <p>
            Scarica la versione di Cashapp per il tuo dispositivo
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <a href={d.link}>
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="download-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                </a>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
