import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
//import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";
//import { Team } from "./components/Team";
import { Contact } from "./components/contact";
//import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {Download} from "./components/download";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
      fetch(props.static_path + '/data/data.json',
          {
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
      )
          .then(async function(response){
              let data = await response.json()
              setLandingPageData(data);
          })
          .then(function(myJson) {
              console.log(myJson);
          });
  }, []);

  return (
    <div>
      <Navigation static_path={props.static_path}/>
      <Header data={landingPageData.Header} static_path={props.static_path}/>
      <Features data={landingPageData.Features} static_path={props.static_path}/>
      <About data={landingPageData.About} static_path={props.static_path}/>
      {/*<Services data={landingPageData.Services} />*/}
      <Download data={landingPageData.Download} static_path={props.static_path}/>
      <Gallery data={landingPageData.Gallery2} static_path={props.static_path}/>
      {/*<Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} static_path={props.static_path}/>
    </div>
  );
};

export default App;
